import Vue from "vue";
import Vuex from "vuex";
import { $api, setProp, deleteProp } from 'bh-mod'

Vue.use(Vuex);

export default {
	state: {
		selectedWorksheetForm: null,
		selectedWorksheet: null,
		worksheetForms: [],
		worksheets: [],
		allSettings: {},
		loadingPage: false,
		getStarted: false,
		lots: [],
		units: [],
		addOns: {},
		vendors: { docusign: {} },
		totalWorksheets: 0,
		sortQuery: '',
		filterDrawer: {
			visible: false,
			filter: {
				lots: [],
				units: [],
				status: ['any'],
			},
		},
		contractTemplates: [],
		reservationAmount: 0,
		reservationCurrency: 'USD',
		participants: [],
		inclusions: [],
		pdfPreview: {
			url: null,
			file: null,
			show: false,
			callback: false,
			confirm: false,
			save: false,
			isManual: false,
			buttonText: "",
		},
		note: {
			note: {},
			type: "",
			visible: false,
		},
		requiredMutualRelease: true,
		identificationTypes: [
			{ label: 'US/CA Passport', value: 'pp' },
			{ label: 'PR', value: 'pr' },
			{ label: 'Work Permit', value: 'wp' },
			{ label: 'Other', value: 'other' },
		],
		passportOther: {
			idType:{
				pp: 'Passport',
				pr: 'PR',
				wp: 'Work Permit',
				other: 'Other'
			},
			valueLabel:{
				pp: 'Passport #',
				pr: 'PR #',
				wp: 'Work Permit #',
				other: 'Other #'
			},
			issuingLabel: {
				pp: 'Passport Issuing Jurisdiction',
				pr: 'PR Issuing Jurisdiction',
				wp: 'Work Permit Issuing Jurisdiction',
				other: 'Other Issuing Jurisdiction'
			},
			issuingCountryLabel: {
				pp: 'Passport Issuing Jurisdiction - Country',
				pr: 'PR Issuing Jurisdiction - Country',
				wp: 'Work Permit Issuing Jurisdiction - Country',
				other: 'Other Issuing Jurisdiction - Country'
			},
			expiryLabel: {
				pp: 'Passport Expiry',
				pr: 'PR Expiry',
				wp: 'Work Permit Expiry',
				other: 'Other Expiry'
			}
		},
	},
	mutations: {
		SET_WORKSHEET_FORM(state, data = {}) {
			state.selectedWorksheetForm = data
		},
		SET_WORKSHEET(state, data) {
			if (data) {
				const fieldData = {}
				data.data.fields.forEach(f => {
					fieldData[f.key] = f.value;
				})
				state.selectedWorksheet = {
					...data,
					data: fieldData
				};
				state.selectedWorksheetForm = data.form;
			} else {
				state.selectedWorksheet = null
			}
		},
		SET_SETTINGS(state, data) {
			let temp = data;
			if (data.userApp == null) {
				temp.userApp = {
					options: {
						seenIntro: false,
					},
				};
				state.allSettings = temp;
			} else {
				state.allSettings = data;
			}

			state.addOns = data && data.app && data.app.options && data.app.options.addons || { parking: [], lockers: [], bikeRacks: [] };
		},
		UPDATE_CONDO_ADDONS(state, data) {
			state.addOns = data || { parking: [], lockers: [], bikeRacks: [] };
		},
		SET_WORKSHEETS(state, data) {
			const worksheets = data.map(w => {
				const data = {}
				w.data.fields.forEach(f => {
					data[f.key] = f.value;
				})

				return {
					...w,
					data
				}
			})
			state.worksheets = worksheets
		},
		SET_WORKSHEET_COUNT(state, data) {
			state.totalWorksheets = data || 0;
		},
		SET_LOTS(state, data) {
			state.lots = data.map(lot => {
				if (!lot.status) {
					lot.status = 'available'
				}
				return lot;
			}) || [];
		},
		SET_UNITS(state, data) {
			state.units = data.map(unit => {
				if (!unit.salesStatus) {
					unit.salesStatus = 'available'
				}
				return unit;
			}) || [];
		},
		SET_CONTRACT_TEMPLATE(state, data) {
			state.contractTemplates = data || [];
		},
		SET_APPDATA(state, data) {
			if (!data) data = {};
			state.worksheetForms = data.worksheetForms || {};
			state.vendors = data.vendors || { docusign: {} };
			state.lots = data.lots && data.lots.map(unit => {
				if (!unit.status) {
					unit.status = 'available'
				}
				return unit;
			}) || [];
			state.units = data.units && data.units.map(unit => {
				if (!unit.salesStatus) {
					unit.salesStatus = 'available'
				}
				return unit;
			}) || [];
		},
		GET_STARTED: (state) => {
			state.getStarted = true;
		},
		LOAD_PAGE: (state, status = !state.loadingPage) => state.loadingPage = status,
		OPEN_FILTER(state) {
			state.filterDrawer.visible = true;
		},
		CLOSE_FILTER(state) {
			state.filterDrawer.visible = false;
		},
		UPDATE_FILTER(state, data) {
			state.filterDrawer.filter = data;
		},
		RESET_DEFAULT_FILTER(state) {
			state.filterDrawer.filter = {
				lots: [],
				units: [],
				status: ['any']
			};
		},
		SET_RESERVATION_DETAILS(state, data) {
			state.reservationAmount = data.value || 0;
			state.reservationCurrency = data.currency || 'USD';
			state.requiredMutualRelease = data.requiredMutualRelease
		},
		SET_PARTICIPANTS(state, data) {
			state.participants = data;
		},
		SET_INCLUSIONS(state, data) {
			state.inclusions = data;
		},
		REVIEW_SEND(state, { preview, callback, isManual, buttonText }) {
			openPDFInNewTab(preview);

			state.pdfPreview = {
				url: preview,
				file: null,
				callback,
				show: true,
				confirm: true,
				save: false,
				isManual,
				title: "",
				buttonText,
			}
		},
		PAGE_PREVIEW_CALLBACK: (state) => {
			if (typeof state.pdfPreview.callback === "function") {
				state.pdfPreview.callback();
			}
		},
		CLOSE_PREVIEW: (state) => (state.pdfPreview = { url: null, file: null, show: false }),
		SHOW_PREVIEW(state, data) {
			openPDFInNewTab(data)
			state.pdfPreview = { url: data, file: null, show: true }
		},
		SHOW_NOTE_MODAL(state, data) {
			state.note = {
				note: data.note,
				type: data.type,
				visible: true,
			};
		},
		CLOSE_NOTE_MODAL(state) {
			state.note = {
				note: {},
				type: "",
				visible: false,
			};
		},
		SET_PROP: (state, { where, what, del = false }) => {
			if (!where.length) return;
			if (del) return deleteProp(state, where);
			if (what.status === null) what.status = "bh_lead";
			else if (typeof what.status === "object") what.status = what.status.id;

			if (what.tags && what.tags.length) {
				what.tags = what.tags.map((x) => {
					if (!state.tags[x.id] && typeof x === "object")
						setProp(state, ["tags", x.id], x);
					return x.id;
				});
			}

			setProp(state, where, what);
		},
		UPDATE_DOCUSIGN(state, data = false) {
			state.vendors = data || { docusign: { connectUrl: state.vendors.docusign.connectUrl} };
		},
	},
	actions: {
		SET_APPDATA: ({ commit, dispatch }, data) => {
			commit("SET_APPDATA", data),
			dispatch('FETCH_INVENTORY_SETTINGS')
			dispatch('FETCH_TRANSACTION_SETTINGS')
		},

		async FETCH_WORKSHEET({ commit }, worksheetId) {
			try {
				const { data } = await $api.get(`/worksheets/:instance/${worksheetId}`);
				commit('SET_WORKSHEET', data)
			} catch (error) {
				console.error('Error occurred while fetching worksheet details', error)
			}
		},

		async FETCH_WORKSHEETS({ commit }) {
			try {
				const { data } = await $api.get(`/worksheets/:instance?_limit=-1`);
				commit('SET_WORKSHEETS', data)
			} catch (error) {
				console.error('Error occurred while fetching worksheet list', error)
			}
		},

		async FETCH_LOTS({ commit }) {
			try {
				const { data } = await $api.get(`/lots/:instance`);
				const lots = data;
				commit('SET_LOTS', lots)
			} catch (error) {
				console.error('Error occurred while fetching lot list', error)
			}
		},

		async FETCH_UNITS({ commit }) {
			try {
				const { data } = await $api.get(`/units/:instance`);
				const units = data && data.filter(u => u.buildingType === 'condounits') || [];
				commit('SET_UNITS', units)
			} catch (error) {
				console.error('Error occurred while fetching unit list', error)
			}
		},

		async FETCH_ADD_ONS({ commit }) {
			try {
				const { data } = await $api.get(`units/:instance/addons`);
				commit("UPDATE_CONDO_ADDONS", data);
			} catch (error) {
				console.error('Error occurred while fetching add on list', error)
			}
		},

		async FETCH_INVENTORY_SETTINGS({ commit }) {
			try {
				const { data } = await $api.get(`/settings/:instance/inventory`);
				const reservationAmount = data && data.options && data.options.reservationAmount || { value: 0, currency: 'USD' };
				const reservationRelease = data && data.options && data.options.reservationRelease || { requiredMutualRelease: true };
				commit('SET_RESERVATION_DETAILS', { ...reservationAmount, requiredMutualRelease: reservationRelease.requiredMutualRelease })
			} catch (error) {
				console.error('Error occurred while fetching unit list', error)
			}
		},

		async FETCH_TRANSACTION_SETTINGS({ commit }) {
			try {
				const { data } = await $api.get(`/settings/:instance/transactions`);
				const participants = data && data.options && data.options.participants && data.options.participants || []
				const inclusions = data && data.options && data.options.inclusions && data.options.inclusions || []
				commit('SET_PARTICIPANTS', participants)
				commit('SET_INCLUSIONS', inclusions)
			} catch (error) {
				console.error('Error occurred while fetching unit list', error)
			}
		},

		async FETCH_CONTRACT_TEMPLATE({ commit }) {
			try {
				const { data } = await $api.get(`/contract-templates/:instance`);
				commit('SET_CONTRACT_TEMPLATE', data)
			} catch (error) {
				console.error('Error occurred while fetching worksheet list', error)
			}
		},
	},
	getters: {
		needsRelease(state, getters) {
			let envelops = getters.signEnvelopes;
			let releaseExist = envelops.some(
				(d) => d.type && d.type === "release" && d.status !== "voided"
			);
			let sofar =
				!releaseExist && (state.selectedWorksheet && !state.selectedWorksheet.status.includes("void"));
			return sofar;
		},

		signEnvelopes: (state) => {
			if (!state.selectedWorksheet || !state.selectedWorksheet.reservation || !state.selectedWorksheet.reservation.envelops)
				return [];
			return state.selectedWorksheet.reservation.envelops.filter(
				(x) => x.esign || x.isManual
			);
		},
	},
};


function openPDFInNewTab(url) {
	const userAgent = navigator.userAgent;
	console.log('userAgent', userAgent)

	// Check if the user is using a mobile device then open pdf in new tab.
	if (userAgent.match(/(iPhone|iPad|Macintosh)/i) && navigator.maxTouchPoints && navigator.maxTouchPoints > 2) {
		window.open(url, '_blank');
	}
}
